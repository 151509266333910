// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';

// Magento/blank
.page-print {
    .logo {
        display: none;
    }
}

@media print {

    table tbody tr td:before,
    table tbody tr td:after {
        content : "" ;
        height : 4px ;
        display : block ;
    }

    table tr td, table tr th {
        page-break-inside: avoid;
    } 
    
    body {
        margin: 0;
    }

    .gallery-product {
        .gallery-media:not(:first-child) {
            display: none !important;
        }
    }

    .product.overflowed {
        button {
            display: none !important;
        }
    }

    .table.grouped {
        .control {
            .qty {
                border-color: transparent;
            }
        }
    }

    .table-wrapper table {
        table-layout: fixed;
        width: 100%;

        td {
            width: auto;
        }


        & > tfoot > tr:first-child {
            border-top: 1px solid $color-gray60;
        }
    }

    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        -webkit-filter: none !important; // Use in 41 Chrome
        filter: none !important;
    }

    // Black prints faster:h5bp.com/s
    a,
    a:visited {
        text-decoration: underline !important;
    }

    // Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        border: 1px solid $color-gray60;
        page-break-inside: avoid;
    }

    .box,
    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1cm;
    }

    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .block-content {
        page-break-before: avoid;
    }

    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }

    .nav-toggle,
    .navigation,
    .benefits-bar,
    .box-tocart,
    .product-info-main-share,
    .breadcrumbs,
    .product-info-main-top,
    .product-social-links,
    .js-gallery-magnify,
    .gallery-thumbnails,
    .message.global.cookie,
    .stock-message,
    .amrelated-grid-wrapper,
    .block.widget,
    .review-fieldset {
        display: none !important;
    }

    .sidebar,
    .nav-sections,
    .header.content > *[class],
    .panel.wrapper > *[class],
    .footer.content > *[class] {
        display: none !important;
    }

    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }

    .order-details-items {
        .order-items {
            .order-gift-message {
                &:not(.expanded-content) {
                    visibility: visible;
                    height: auto;
                }
            }
        }
    }

    .column.main {
        width: 100% !important;
        float: none !important;
        padding-bottom: 0 !important;
    }

    .page-title-wrapper {
        margin-top: 15px;

        .page-title {
            font-size: 2.5rem;
        }
    }

    .product-info-main {
        float: right;
        width: 47%;
    }

    .product.media {
        float: left;
        width: 200px;
        margin-left: 15%;

        .slick-slide,
        .slick-track {
            width: 100% !important;
            transform: none !important;
        }
    }

    .block.block-grouped {
        table {
            .col.qty {
                &,
                ~ td,
                ~ th {
                    display: none;
                }
            }
        }

        .stock-message {
            display: inline-block !important;
        }
    }

    .data.item.title {
        text-align: center;

        .data.switch {
            text-decoration: none !important;
        }

        &:not(:first-child) {
            display: none;
        }
    }

    .product.data.items > .item.content {
        border: 0;
    }

    .product-add-to-cart {
        border: 0;
    }

    .footer.content {
        padding: 0;
    }

    .table.grouped {
        display: block;

        thead {
            display: block;
            border-bottom: 1px solid #d1d1d1;
        }

        tbody {
            display: block;
        }

        tr {
            display: flex;
            flex-flow: wrap;
        }

        th,
        td {
            display: block;
        }

        th {
            border: 0 !important;
        }
    }

    .bundle-options-wrapper {
        .table.grouped {
            tr {
                td,
                th {
                    width: 50%;
                    
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}
